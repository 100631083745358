<template>
  <div>
    <el-card
      class="box-card"
      shadow="hover"
      @mouseleave="validateAndSave()"
      style="margin: 0 auto"
    >
      <div slot="header" class="clearfix">
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="deleteNodeForm"
          :disabled="nodeOptions['required']"
          >删除</el-button
        >
      </div>
      <div class="text item">
        <el-form :model="viewItems" ref="viewItems">
          <el-form-item
            v-for="(opt, index) in $options.nodesOptions"
            :key="index"
            :label="opt.label"
            :rules="opt.rules"
            :prop="opt.name"
            :ref="opt.name"
            :id="opt.name"
          >
            <el-radio-group
              v-if="opt.formType === 'radio-group' && !opt.undisplay"
              v-model="viewItems[opt.name]"
              size="large"
            >
              <el-radio-button
                v-for="(radio, index) in formItemOptions[opt.name]"
                :key="index"
                :label="radio.label"
              >
                {{ radio.text }}
              </el-radio-button>
            </el-radio-group>

            <el-select
              v-if="opt.formType === 'select' && !opt.undisplay"
              :placeholder="opt.placeholder"
              v-model="viewItems[opt.name][0]"
            >
              <el-option
                v-for="(radio, index) in formItemOptions[opt.name]"
                :key="index"
                :label="radio.label"
                :value="radio.value"
              ></el-option>
            </el-select>

            <el-checkbox-group
              v-model="viewItems[opt.name]"
              v-if="opt.formType === 'checkbox-group' && !opt.undisplay"
            >
              <el-checkbox-button
                v-for="(radio, index) in formItemOptions[opt.name]"
                :key="index"
                :label="radio.label"
                >{{ radio.text }}</el-checkbox-button
              >
            </el-checkbox-group>

            <el-input
              v-if="opt.formType === 'input' && !opt.undisplay"
              v-model="viewItems[opt.name]"
              :type="opt.type"
              :disabled="!!(opt.name === 'kubernetesConfigRuntime')"
            ></el-input>

            <el-form
              v-if="opt.formType === 'inlineInput' && !opt.undisplay"
              :inline="true"
              :disabled="opt.name === 'spotPriceLimit'"
            >
              <el-form-item>
                <el-input
                  :placeholder="opt.key.placeholder"
                  v-model="viewItems[opt.name + 'Obj'].key"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  :placeholder="opt.value.placeholder"
                  v-model="viewItems[opt.name + 'Obj'].value"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="addKeyValue(opt.name)">添加</el-button>
              </el-form-item>

              <!-- 展示已经添加的键值对 -->
              <el-form
                :inline="true"
                v-for="(arrItem, index) in viewItems[opt.name + 'Arr']"
                :key="arrItem.key"
              >
                <el-form-item>
                  <el-input v-model="arrItem.key" disabled></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input v-model="arrItem.value" disabled></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    @click="deleteKeyValue(opt.name, arrItem.key)"
                    :disabled="index === 0"
                    >删除</el-button
                  >
                </el-form-item>
              </el-form>
            </el-form>
          </el-form-item>
          <el-switch
            v-model="additionFlag"
            active-text="高级选项"
            style="width: 400px"
          ></el-switch>
          <el-form :model="viewItems" ref="viewItems" v-if="additionFlag">
            <el-form-item
              v-for="(optAdd, indexAdd) in $options.nodesOptionsAdd"
              :key="indexAdd"
              :label="optAdd.label"
              :rules="optAdd.rules"
              :prop="optAdd.name"
              :ref="optAdd.name"
              :id="optAdd.name"
            >
              <el-select
                v-if="optAdd.formType === 'select' && !!optAdd.undisplay"
                :placeholder="optAdd.placeholder"
                v-model="viewItems[optAdd.name][0]"
              >
                <el-option
                  v-for="(radio, index) in formItemOptions[optAdd.name]"
                  :key="index"
                  :label="radio.label"
                  :value="radio.value"
                ></el-option>
              </el-select>

              <el-input
                v-if="optAdd.formType === 'input' && !!optAdd.undisplay"
                v-model="viewItems[optAdd.name]"
                :type="optAdd.type"
                :disabled="!!(optAdd.name === 'kubernetesConfigRuntime')"
              ></el-input>

              <el-form
                v-if="optAdd.formType === 'inlineInput' && !!optAdd.undisplay"
                :inline="true"
                :disabled="optAdd.name === 'spotPriceLimit'"
              >
                <el-form-item>
                  <el-input
                    :placeholder="optAdd.key.placeholder"
                    v-model="viewItems[optAdd.name + 'Obj'].key"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input
                    :placeholder="optAdd.value.placeholder"
                    v-model="viewItems[optAdd.name + 'Obj'].value"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button @click="addKeyValue(optAdd.name)">添加</el-button>
                </el-form-item>

                <!-- 展示已经添加的键值对 -->
                <el-form
                  :inline="true"
                  v-for="(arrItem, index) in viewItems[opt.name + 'Arr']"
                  :key="arrItem.key"
                >
                  <el-form-item>
                    <el-input v-model="arrItem.key" disabled></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-input v-model="arrItem.value" disabled></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      @click="deleteKeyValue(opt.name, arrItem.key)"
                      :disabled="index === 0"
                      >删除</el-button
                    >
                  </el-form-item>
                </el-form>
              </el-form>
            </el-form-item>
          </el-form>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import { nodesOptions } from "../../../assets/formObj/ali";
export default {
  props: ["nodeOptions"],
  cardTypeMap: {
    // DefaultPool: {
    //   type: "default",
    //   defaultNodesType: "spark-applications-driver-nodes",
    // },
    DriverPool: {
      type: "normal",
      defaultNodesType: "spark-applications-driver-nodes",
    },
    ApplicationPool: {
      type: "normal",
      defaultNodesType: "spark-applications-nodes",
    },
  },

  watch: {
    /**
     * cardType的修改会导致两部分内容的修改：1) 发送给后端的type字段  2) nodeLabels的默认字段
     */

    "viewItems.cardType"(newCardType) {
      this.modifyTypeField(newCardType);
      this.modifyDefaultNodesType(newCardType);
    },
  },

  created() {
     // 页面展示的表单项的静态部分,如标签,验证规则等 并分为普通选项和高级选项
    this.$options.nodesOptions = new Array()
    this.$options.nodesOptionsAdd = new Array()
    Object.values(nodesOptions).map((nodesOption)=>{
      if(!nodesOption.undisplay) this.$options.nodesOptions.push(nodesOption)
      else this.$options.nodesOptionsAdd.push(nodesOption)
    })
    this.displayExistedData();
  },

  directives: {
    focus: {
      // directive definition
      mounted: function (el, binding) {
        console.log("bind");
        console.log(binding.value);
        console.log(binding.arg);
        if (binding.value === binding.arg) {
          console.log("focus here");
          el.scrollIntoView({ block: "center" });
        }
      },
    },
  },

  data() {
    return {
      additionFlag: false,
      viewItems: {
        cardType: "",
        type: "",
        cloud: "",
        nodePoolName: "",
        nodeNums: "",
        nodeLabelsArr: [],
        nodeLabelsObj: {
          // 形式上与待提交的表单项不同
          key: "",
          value: "",
        },
        resourceTagsArr: [],
        resourceTagsObj: {
          // 形式上与待提交的表单项不同
          key: "",
          value: "",
        },
        // systemDiskPerformanceLevelArr: [],
        // systemDiskPerformanceLevelObj: {
        //   // 形式上与待提交的表单项不同
        //   key: "",
        //   value: "",
        // },
        spotPriceLimitArr: [],
        spotPriceLimitObj: {
          // 形式上与待提交的表单项不同
          key: "",
          value: "",
        },
        dataDisks: [],
        scalingGroupInstanceTypes: [],
        // scalingGroupVswitchIds: [],

        nodepoolInfoResourceGroupID: "",
        nodepoolInfoType: "",
        enableAutoScaling: true,
        autoScalingMaxInstances: "",
        autoScalingMinInstances: "",
        autoScalingType: [],
        instance_charge_type: "",
        // soc_enabled: false,
        // cis_enabled: false,
        scalingGroupKeyPair: "",
        scalingGroupSpotStrategy: [],
        onDemandBaseCapacity: "",
        onDemandPercentageAboveBaseCapacity: "",
        scalingGroupSecurityGroupIds: "",
        systemDiskCategory: [],
        systemDiskSize: 40,
        // systemDiskPerformanceLevel: "",
        scalingGroupIsEnterpriseSecurityGroup: true,
        scalingGroupPlatform: "",
        image_type: "",
        kubernetesConfigRuntime: "",
        kubernetesConfigRuntimeVersion: "",
      },

      helpers: {
        valid: false,
        focusItem: "xxx",
      },

      defaultLoginType: "sshKeyName",

      formItemOptions: {
        // 节点类型
        dataDisks: [
          {
            label: "s6.large.2",
            text: "s6.large.2",
          },
        ],
        scalingGroupInstanceTypes: [
          {
            label: "ecs.c6.xlarge",
            text: "ecs.c6.xlarge",
          },
        ],
        autoScalingType: [
          {
            value: "cpu",
            label: "普通实例型",
          },
          {
            value: "gpu",
            label: "GPU实例型",
          },
          {
            value: "gpushare",
            label: "GPU共享型",
          },
          {
            value: "spot",
            label: "抢占式实例型",
          },
        ],
        scalingGroupSpotStrategy: [
          {
            value: "NoSpot",
            label: "NoSpot",
          },
          {
            value: "SpotWithPriceLimit",
            label: "SpotWithPriceLimit",
          },
          {
            value: "SpotAsPriceGo",
            label: "SpotAsPriceGo",
          },
        ],
        // scalingGroupVswitchIds: [
        // {
        //   label: "vsw-hp3v4u8v1uq2n4wpdrogl",
        //   text: "vsw-hp3v4u8v1uq2n4wpdrogl",
        // },
        // ],

        // "卡片"类型
        cardType: [
          // {
          //   text: "DefaultPool",
          //   label: "DefaultPool",
          // },
          {
            text: "DriverPool",
            label: "DriverPool",
          },
          {
            text: "ApplicationPool",
            label: "ApplicationPool",
          },
        ],
        instance_charge_type: [
          {
            text: "包年/包月",
            label: "PrePaid",
          },
          {
            text: "按需付费",
            label: "PostPaid",
          },
        ],
        systemDiskCategory: [
          {
            label: "高效云盘",
            value: "cloud_efficiency",
          },
          {
            label: "SSD云盘",
            value: "cloud_ssd",
          },
          {
            label: "ESSD云盘",
            value: "cloud_essd",
          },
        ],
      },
    };
  },

  methods: {
    // 直接修改待发送的nodesOptions.type字段
    modifyTypeField(cardType) {
      this.nodeOptions["type"] = this.$options.cardTypeMap[cardType]["type"];
    },

    // 直接修改待发送的nodesOptions.nodeLabels字段
    modifyDefaultNodesType(cardType) {
      const nodesTypeVal =
        this.$options.cardTypeMap[cardType]["defaultNodesType"];
      if (this.viewItems["nodeLabelsArr"].length === 0) {
        this.viewItems["nodeLabelsArr"].unshift({
          key: "nodestype",
          value: nodesTypeVal,
        });
      } else {
        this.viewItems["nodeLabelsArr"][0].value = nodesTypeVal;
      }

      this.nodeOptions["nodeLabels"] = this.kvArrToObj(
        this.viewItems["nodeLabelsArr"]
      );
    },

    displayKvLabels() {
      const kvs = ["resourceTags", "nodeLabels", "spotPriceLimit"];

      kvs.forEach((kv) => {
        this.viewItems[kv + "Arr"] = this.kvObjToArr(this.nodeOptions[kv]);
      });
    },

    validateAndSave() {
      this.validateSave("viewItems");
    },

    getCardType(type, nodesType) {
      for (let [cardType, relations] of Object.entries(
        this.$options.cardTypeMap
      )) {
        if (
          relations["type"] === type &&
          relations["defaultNodesType"] === nodesType
        ) {
          return cardType;
        }
      }

      // 不能够确定类型,返回空字符串,与data中的数据相对应
      return "";
    },

    displayExistedData() {
      // 根据传入的数据,判断cardType类型
      this.viewItems["cardType"] = this.getCardType(
        this.nodeOptions["type"],
        this.nodeOptions.nodeLabels["nodestype"]
      );

      // 展示已经存在的KV值
      this.displayKvLabels();

      for (let [viewKey, viewVal] of Object.entries(this.viewItems)) {
        if (!Object.hasOwn(this.nodeOptions, viewKey)) {
          // 过滤掉this.nodesOptions中没有的key
          continue;
        }
        this.viewItems[viewKey] = this.nodeOptions[viewKey];
      }
    },

    addKeyValue(kvType) {
      // 获取到用户输入的键和值
      const key = this.viewItems[kvType + "Obj"].key;
      const value = this.viewItems[kvType + "Obj"].value;

      // 判断用户输入的key值是否已经存在, 如果存在则直接返回
      const kvs = this.viewItems[kvType + "Arr"];

      for (let i = 0; i < kvs.length; i++) {
        if (kvs[i].key === key) {
          // 可以在这儿写一些提醒用户的逻辑
          return;
        }
      }

      if (!key || !value) {
        // 可以在这写一些提醒用户的逻辑
        return;
      }

      // 用户输入的key和value均存在
      // 更新数组
      const obj = {};
      obj.key = key;
      obj.value = value;
      this.viewItems[kvType + "Arr"].push(obj);

      // 清除输入框中的值
      this.viewItems[kvType + "Obj"].key = "";
      this.viewItems[kvType + "Obj"].value = "";
    },

    deleteKeyValue(kvType, itemKey) {
      let delIndex = -1;

      // 删除数组中对应的值
      const tagsArr = this.viewItems[kvType + "Arr"]; // key-value数组
      for (let i = 0; i < tagsArr.length; i++) {
        if (tagsArr[i].key === itemKey) {
          delIndex = i;
          break;
        }
      }
      tagsArr.splice(delIndex, 1); // 删除掉对应元素
    },

    saveNodeResourceKeyValue() {
      for (let nodeLabel of this.viewItems.nodeLabelsArr) {
        // 其实不用担心重复赋值
        this.nodeOptions.nodeLabels[nodeLabel.key] = nodeLabel.value;
      }

      for (let obj of this.viewItems.resourceTagsArr) {
        this.nodeOptions.resourceTags[obj.key] = obj.value;
      }

      for (let obj of this.viewItems.spotPriceLimitArr) {
        this.nodeOptions.spotPriceLimit[obj.key] = obj.value;
      }
    },

    kvObjToArr(kvObj) {
      const kvArr = [];

      for (let [k, v] of Object.entries(kvObj)) {
        kvArr.push({
          key: k,
          value: v,
        });
      }

      return kvArr;
    },

    kvArrToObj(kvArr) {
      const kvObj = {};
      for (let obj of kvArr) {
        kvObj[obj.key] = obj.value;
      }
      return kvObj;
    },

    /**
     * 获取nodeLabels、resourceTags这两个字段发生的变化
     * 如果使用mouseLeave事件, 则该函数可以废弃
     */
    getKvChanges() {
      const kvs = ["nodeLabels", "resourceTags"];
      const changes = [];

      kvs.forEach((kv) => {
        // 对于nodelLabels, resourceTags只有增删操作, 没有修改操作
        let changed = false;
        // 如果视图绑定的数据和发送的数据在长度上没有差异,则去判断每一项是否是完全相等的
        if (
          Object.keys(this.nodeOptions[kv]).length ===
          this.viewItems[kv + "Arr"].length
        ) {
          const viewKvObj = this.kvArrToObj(this.viewItems[kv + "Arr"]);
          for (let [k, v] of Object.entries(this.nodeOptions[kv])) {
            if (viewKvObj[k] !== v) {
              changed = true;
            }
          }
          if (!changed) {
            // 没有发现变化, 直接返回
            return;
          }
        }

        // 存储发生改变的字段、原值、新值
        const field = kv;
        const oldVal = this.kvObjToArr(this.nodeOptions[kv]);
        const newVal = this.viewItems[kv + "Arr"];

        // 可以改进
        changes.push({
          field: field,
          oldVal: oldVal,
          newVal: newVal,
        });
      });

      return changes;
    },

    save() {
      // 将nodeLabels和nodeLabels存储起来
      this.saveNodeResourceKeyValue();
      // 存储其他非kv类型字段
      for (let nodeOptsKey of Object.keys(this.nodeOptions)) {
        if (Object.hasOwn(this.viewItems, nodeOptsKey)) {
          this.nodeOptions[nodeOptsKey] = this.viewItems[nodeOptsKey];
        }
      }
    },

    validateSave(formName) {
      this.$refs[formName].validate((valid, unPassedFields) => {
        if (valid) {
          this.save();
          // this.$message({
          //   message: "验证通过",
          //   type: "success",
          // });
        } else {
          // console.log(unPassedFields);
          // const firstUnpassedField = Object.keys(unPassedFields)[0];  // 第一个未通过验证的字段
          // console.log(this.$refs[firstUnpassedField]);
          // this.$refs[firstUnpassedField].scrollIntoView({ block: 'center' });
          // document.getElementById(firstUnpassedField).scrollIntoView({ block: 'center' });
          // console.log(this.helpers.focusItem);
          // this.helpers.focusItem = '';
          this.$message({
            message: "验证未通过",
            type: "warning",
          });
          return false;
        }

        // 通知父组件
        this.$emit("validationResCome", this.nodeOptions.key, valid);

        this.getUnsavedChanges();
      });
    },

    deleteNodeForm() {
      // console.log(this.nodeOptions.key);
      this.$emit("deleteNodeForm", this.nodeOptions.key);
    },

    /**
     * 获取没有保存的变化
     * 如果使用mouseLeave事件, 则该函数可以废弃
     */
    getUnsavedChanges() {
      const unsavedChanges = [];

      unsavedChanges.push(...this.getKvChanges());

      for (let [viewKey, viewVal] of Object.entries(this.viewItems)) {
        if (!this.nodeOptions.hasOwnProperty(viewKey)) {
          // 排除没有的情况
          continue;
        }

        if (this.nodeOptions[viewKey] !== viewVal) {
          unsavedChanges.push({
            field: viewKey, // 1: 这一块的结果尚未确定
            oldVal: this.nodeOptions[viewKey],
            newVal: viewVal,
          });
        }
      }

      if (unsavedChanges.length === 0) {
        // 如果没有变化
        if (
          this.$store.getters.unsavedChanges.hasOwnProperty(
            this.nodeOptions["key"]
          )
        ) {
          this.$store.commit(
            "deleteUnsavedChangesProp",
            this.nodeOptions["key"]
          );
        }
        return;
      }

      // 这块值传递,未来可以做些改变(变量名重命名之类的)
      const arr = [this.nodeOptions.key, unsavedChanges];

      this.$store.commit("setUnsavedChanges", arr);
    },
  },
};
</script>

<style>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 90%;
}
</style>