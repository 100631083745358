<template>
  <div>
    <div class="title_text">
      <h2>节点公有参数配置</h2>
    </div>
    <el-divider></el-divider>
    <el-form
      :model="viewItems"
      ref="viewItems"
      label-width="200px"
      :inline="true"
      class="loginTypeChoose"
      style="text-align: left"
    >
      <el-card class="box-card" shadow="hover" style="margin: 0 auto">
        <!-- 服务器提供商(单选框按钮组) -->
        <el-form-item
          v-for="(item, index) in $options.nodesCommonOptions"
          :key="index"
          :label="item.label"
          :prop="item.name"
          :rules="item.rules"
          :placeholder="item.placeholder"
        >
          <el-radio-group
            v-if="item.type === 'radio-group'"
            size="large"
            v-model="viewItems[item.name]"
          >
            <el-radio-button
              v-for="(radio, index) in formItemOptions[item.name]"
              :key="index"
              :label="radio.label"
              >{{ radio.text }}</el-radio-button
            >
          </el-radio-group>

          <el-input
            v-show="
              (item.type === 'input' || item.type === 'password') &&
              item.name !== 'loginKeyPair' &&
              item.name !== 'loginPassword'
            "
            v-model="viewItems[item.name]"
            style="width: 210px"
            :type="item.type"
          ></el-input>

          <el-form v-if="item.type === 'inlineInput'" :inline="true">
            <el-form-item>
              <el-input
                :placeholder="item.placeholder"
                v-model="viewItems.workerVswitchID"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button @click="addWorkerVswitchID(viewItems.workerVswitchID)"
                >添加</el-button
              >
            </el-form-item>

            <!-- 展示已经添加的键值对 -->
            <el-form
              :inline="true"
              v-for="(arrItem, index) in viewItems[item.name]"
              :key="index"
            >
              <el-form-item>
                <el-input
                  v-model="viewItems[item.name][index]"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  @click="
                    viewItems[item.name].splice(
                      viewItems[item.name].indexOf(viewItems[item.name][index]),
                      1
                    )
                  "
                  >删除</el-button
                >
              </el-form-item>
            </el-form>
          </el-form>
        </el-form-item>

        <!-- 登录类型(单选框按钮组) -->
        <el-form-item
          :label="$options.nodesCommonOptionsOutOfLoop['loginTypes'].name"
          :placeholder="
            $options.nodesCommonOptionsOutOfLoop['loginTypes'].placeholder
          "
          :rules="$options.nodesCommonOptionsOutOfLoop['loginTypes'].rules"
          :prop="$options.nodesCommonOptionsOutOfLoop['loginTypes'].name"
        >
          <el-radio-group size="large" v-model="viewItems['loginTypes']">
            <el-radio-button
              v-for="(radio, index) in formItemOptions['loginTypes']"
              :key="index"
              :label="radio.label"
              >{{ radio.text }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>

        <!-- loginKeyPair输入框 -->
        <el-form-item
          v-show="viewItems['loginTypes'] === 'loginKeyPair'"
          :label="$options.nodesCommonOptionsOutOfLoop['loginKeyPair'].label"
          :placeholder="
            $options.nodesCommonOptionsOutOfLoop['loginKeyPair'].placeholder
          "
          :rules="$options.nodesCommonOptionsOutOfLoop['loginKeyPair'].rules"
          :prop="$options.nodesCommonOptionsOutOfLoop['loginKeyPair'].name"
        >
          <el-input
            v-model="viewItems['loginKeyPair']"
            :type="$options.nodesCommonOptionsOutOfLoop['loginKeyPair'].type"
          ></el-input>
        </el-form-item>

        <!-- loginPassword输入框 -->
        <el-form-item
          v-show="viewItems['loginTypes'] === 'loginPassword'"
          :label="$options.nodesCommonOptionsOutOfLoop['loginPassword'].label"
          :placeholder="
            $options.nodesCommonOptionsOutOfLoop['loginPassword'].placeholder
          "
          :rules="$options.nodesCommonOptionsOutOfLoop['loginPassword'].rules"
          :prop="$options.nodesCommonOptionsOutOfLoop['loginPassword'].name"
        >
          <el-input
            autofocus
            v-model="viewItems['loginPassword']"
            :type="$options.nodesCommonOptionsOutOfLoop['loginPassword'].type"
          ></el-input>
        </el-form-item>
        <!-- 确定按钮 -->
        <el-form-item>
          <el-button
            type="primary"
            @click="logInfoSave('viewItems')"
            class="saveButton"
            >展开节点表单</el-button
          >
        </el-form-item>
      </el-card>
    </el-form>

    <div
      v-if="
        helpers.nodeFormsDisplay ||
        this.$store.getters.reqData.nodesOptions.length > 0
      "
    >
      <el-divider></el-divider>
      <div class="title_text">
        <h2>节点项配置</h2>
        <el-divider></el-divider>
        <el-main
          v-for="nodeOpts in nodesOptions"
          :key="nodeOpts.key"
          class="nodesForms"
        >
          <AliNodeForm
            class="formCompo"
            :nodeOptions="nodeOpts"
            ref="childForms"
            @validationResCome="getValidation"
            @deleteNodeForm="deleteNodeForm"
          ></AliNodeForm>
        </el-main>
        <div style="height: 60px">
          <el-button type="primary" @click="addNodeForm" class="newNodeButton"
            >新增节点</el-button
          >
        </div>
        <!-- <el-button type="primary" @click="test">test</el-button> -->
      </div>
      <div id="zhanwei"></div>
      <PrevNext
        @prev-click="this.$router.push('/form/aliAccess/aliCluster')"
        @next-click="onNextClick"
      ></PrevNext>
    </div>
  </div>
</template>

<script>
import AliNodeForm from "./AliNodeFormPlus.vue";
import PrevNext from "../../../components/Common/PrevNext.vue";
import { PROCESS_ACTIVE } from "../../../assets/constant.js";
import { v4 as uuidv4 } from "uuid";
import { nodesCommonOptions } from "../../../assets/formObj/ali";

export default {
  data() {
    return {
      nodesOptions: [
        // {
        //   type: "default",
        //   cloud: "ali",
        //   nodePoolName: "default-nodes",
        //   nodeNums: 1,
        //   nodeLabels: {
        //     nodestype: "spark-applications-driver-nodes",
        //   },
        //   resourceTags: {
        //     team: "dmetasoul",
        //   },
        //   spotPriceLimit: {
        //     1.6: "ecs.sn2ne.xlarge",
        //   },
        //   dataDisks: ["s6.large.2"],
        //   scalingGroupInstanceTypes: ["ecs.c6.xlarge"],
        //   scalingGroupVswitchIds: "",

        // // nodepoolInfoResourceGroupID: "rg-acfmv3yht2b5rny",
        // nodepoolInfoResourceGroupID: "",
        //   nodepoolInfoType: "ess",
        //   enableAutoScaling: true,
        //   autoScalingMaxInstances: "3",
        //   autoScalingMinInstances: "1",
        //   autoScalingType: "cpu",
        //   instance_charge_type: "PostPaid",
        //   soc_enabled: false,
        //   cis_enabled: false,
        //   scalingGroupKeyPair: "dmetasoul_aliyun",
        //   scalingGroupSpotStrategy: "NoSpot",
        //   onDemandBaseCapacity: "1",
        //   onDemandPercentageAboveBaseCapacity: "1",
        //   scalingGroupSecurityGroupIds: "",
        //   systemDiskCategory: "cloud_efficiency",
        //   systemDiskSize: 40,
        //   systemDiskPerformanceLevel: "",
        //   scalingGroupIsEnterpriseSecurityGroup: true,
        //   scalingGroupPlatform: "AliyunLinux",
        //   image_type: "AliyunLinux",
        //   kubernetesConfigRuntime: "docker",
        //   kubernetesConfigRuntimeVersion: "1.5.10",
        // },
        {
          type: "normal",
          cloud: "ali",
          nodePoolName: "dirver-nodes",
          nodeNums: 1,
          nodeLabels: {
            nodestype: "spark-applications-driver-nodes",
          },
          resourceTags: {
            team: "dmetasoul",
          },
          spotPriceLimit: {
            // 1.6: "ecs.sn2ne.xlarge",
          },
          // dataDisks: ["s6.large.2"],
          scalingGroupInstanceTypes: ["ecs.c6.xlarge"],
          scalingGroupVswitchIds: [],

          // nodepoolInfoResourceGroupID: "rg-acfmv3yht2b5rny",
          nodepoolInfoResourceGroupID: "",
          nodepoolInfoType: "ess",
          enableAutoScaling: true,
          autoScalingMaxInstances: "3",
          autoScalingMinInstances: "1",
          autoScalingType: ["cpu"],
          instance_charge_type: "PostPaid",
          // soc_enabled: false,
          // cis_enabled: false,
          // scalingGroupKeyPair: "dmetasoul_aliyun",
          scalingGroupSpotStrategy: ["NoSpot"],
          onDemandBaseCapacity: "1",
          onDemandPercentageAboveBaseCapacity: "1",
          scalingGroupSecurityGroupIds: "",
          systemDiskCategory: ["cloud_efficiency"],
          systemDiskSize: 40,
          // systemDiskPerformanceLevel: {
          //   key: "value",
          // },
          scalingGroupIsEnterpriseSecurityGroup: true,
          // scalingGroupPlatform: "AliyunLinux",
          // image_type: "AliyunLinux",
          kubernetesConfigRuntime: "containerd",
          kubernetesConfigRuntimeVersion: "1.5.10",
        },
        {
          type: "normal",
          cloud: "ali",
          nodePoolName: "application-nodes",
          nodeNums: 1,
          nodeLabels: {
            nodestype: "spark-applications-nodes",
          },
          resourceTags: {
            team: "dmetasoul",
          },
          spotPriceLimit: {
            // 1.6: "ecs.sn2ne.xlarge",
          },
          // dataDisks: ["s6.large.2"],
          scalingGroupInstanceTypes: ["ecs.c6.xlarge"],
          scalingGroupVswitchIds: [],

          // nodepoolInfoResourceGroupID: "rg-acfmv3yht2b5rny",
          nodepoolInfoResourceGroupID: "",
          nodepoolInfoType: "ess",
          enableAutoScaling: true,
          autoScalingMaxInstances: "3",
          autoScalingMinInstances: "1",
          autoScalingType: ["cpu"],
          instance_charge_type: "PostPaid",
          // soc_enabled: false,
          // cis_enabled: false,
          // scalingGroupKeyPair: "dmetasoul_aliyun",
          scalingGroupSpotStrategy: ["NoSpot"],
          onDemandBaseCapacity: "1",
          onDemandPercentageAboveBaseCapacity: "1",
          scalingGroupSecurityGroupIds: "",
          systemDiskCategory: ["cloud_efficiency"],
          systemDiskSize: 40,
          // systemDiskPerformanceLevel: {
          //   key: "value",
          // },
          scalingGroupIsEnterpriseSecurityGroup: true,
          // scalingGroupPlatform: "AliyunLinux",
          // image_type: "AliyunLinux",
          kubernetesConfigRuntime: "containerd",
          kubernetesConfigRuntimeVersion: "1.5.10",
        },
      ],
      viewItems: {
        // workerVswitchID: "",
        // 与当前组件的表单项(视图)绑定
        cloud: "ali",
        workerVswitchIDS:
          this.$store.getters.reqData?.driverOptions?.workerVswitchIDS || [],
        workerInstanceTypes:
          this.$store.getters.reqData?.driverOptions?.workerInstanceTypes ||
          "ecs.c6.xlarge",
        workerSystemDiskCategory:
          this.$store.getters.reqData?.driverOptions
            ?.workerSystemDiskCategory || "cloud_efficiency",
        workerSystemDiskSize:
          this.$store.getters.reqData?.driverOptions?.workerSystemDiskSize ||
          "40",
        workerInstanceChargeType:
          this.$store.getters.reqData?.driverOptions
            ?.workerInstanceChargeType || "PostPaid",
        loginTypes: "loginPassword",
        resourceGroupID:
          this.$store.getters.reqData?.driverOptions?.resourceGroupID || "",
      },
      formItemOptions: {
        // 云提供商
        cloud: [
          {
            text: "阿里云",
            label: "ali",
          },
        ],

        // 付费方式
        workerInstanceChargeType: [
          {
            text: "包年/包月",
            label: "PrePaid",
          },
          {
            text: "按需付费",
            label: "PostPaid",
          },
        ],

        // 登录方式
        loginTypes: [
          {
            text: "loginKeyPair",
            label: "loginKeyPair",
          },
          {
            text: "loginPassword",
            label: "loginPassword",
          },
        ],
      },

      helpers: {
        nodeFormsKeys: [], // 建立key与this.nodesOptions数组下标的对应
        validationArr: [true, true],
        nodeFormsDisplay: false,
      },
    };
  },

  components: {
    AliNodeForm,
    PrevNext,
  },

  created() {
    // 导航栏状态的同步
    this.$store.dispatch("setProcessActive", PROCESS_ACTIVE.NODE);
    //每次进入本页面 清空vuex中密码 防止重复加密报错
    if (!!this.viewItems.loginPassword) {
      this.viewItems.loginPassword = "";
    }
    //每次进入本页面把dirver里的resourceGroupID置空
    delete this.$store.getters.reqData.driverOptions.resourceGroupID;
    // 如果用户已经提交过节点配置表单, 获取之前的输入
    if (this.$store.getters.reqData.nodesOptions.length > 0) {
      this.getOriUserInput();
    }
    // 添加key和required(是否为必填表单)等信息
    this.addKeysAndRequired(this.nodesOptions);

    // 本页面表单部分的静态数据, 如表单类型, 验证规则等

    // 所有节点共同的选项,如登录账户和密码等
    this.$options.nodesCommonOptions = {};
    this.$options.nodesCommonOptionsOutOfLoop = {};
    for (const [key, nodesCommonOption] of Object.entries(nodesCommonOptions)) {
      console.log(key, nodesCommonOption);
      if (
        nodesCommonOption.name !== "loginTypes" &&
        nodesCommonOption.name !== "loginKeyPair" &&
        nodesCommonOption.name !== "loginPassword"
      ) {
        this.$options.nodesCommonOptions[key] = nodesCommonOption;
      } else {
        this.$options.nodesCommonOptionsOutOfLoop[key] = nodesCommonOption;
      }
    }

    // 从下一步跳回本步骤时要利用备份对已经转化为[{}]结构的spotPriceLimit做恢复 以及对autoScalingType和scalingGroupSecurityGroupIds做恢复，用于展示和修改
    this.nodesOptions.forEach((nodesOption) => {
      if (!!nodesOption.spotPriceLimitCopy) {
        nodesOption.spotPriceLimit = nodesOption.spotPriceLimitCopy;
      }
      if (!!nodesOption.autoScalingTypeCopy) {
        nodesOption.autoScalingType = nodesOption.autoScalingTypeCopy;
      }
      nodesOption.nodepoolInfoResourceGroupID = "";
      nodesOption.scalingGroupSecurityGroupIds =
        nodesOption.scalingGroupSecurityGroupIds[0];
    });

    // 新增节点表单的样板数据,包含一些默认值等
    this.$options.nodeFormTemplate = {
      cardType: "DriverPool",
      type: "normal",
      cloud: "ali",
      nodePoolName: "",
      nodeNums: 1,
      nodeLabels: {},
      resourceTags: {
        team: "dmetasoul",
      },
      spotPriceLimit: {
        // 1.6: "ecs.sn2ne.xlarge",
      },
      // dataDisks: ["s6.large.2"],
      scalingGroupInstanceTypes: ["ecs.c6.xlarge"],
      scalingGroupVswitchIds: [],

      // nodepoolInfoResourceGroupID: "rg-acfmv3yht2b5rny",
      nodepoolInfoResourceGroupID: "",
      nodepoolInfoType: "ess",
      enableAutoScaling: true,
      autoScalingMaxInstances: "3",
      autoScalingMinInstances: "1",
      autoScalingType: ["cpu"],
      instance_charge_type: "PostPaid",
      // soc_enabled: false,
      // cis_enabled: false,
      // scalingGroupKeyPair: "dmetasoul_aliyun",
      scalingGroupSpotStrategy: ["NoSpot"],
      onDemandBaseCapacity: "1",
      onDemandPercentageAboveBaseCapacity: "1",
      scalingGroupSecurityGroupIds: "",
      systemDiskCategory: ["cloud_efficiency"],
      systemDiskSize: 40,
      // systemDiskPerformanceLevel: {
      //   key: "value",
      // },
      scalingGroupIsEnterpriseSecurityGroup: true,
      // scalingGroupPlatform: "AliyunLinux",
      // image_type: "AliyunLinux",
      kubernetesConfigRuntime: "containerd",
      kubernetesConfigRuntimeVersion: "1.5.10",
    };
  },

  methods: {
    addWorkerVswitchID(workerVswitchID) {
      if (!workerVswitchID || workerVswitchID === undefined) {
        this.$message({
          message: "workerVswitchID值不可为空",
          type: "warning",
        });
        return;
      } else {
        this.viewItems.workerVswitchIDS.push(workerVswitchID);
      }
    },
    // 给每个nodeOption对象附加key和required信息
    addKeysAndRequired() {
      this.nodesOptions.forEach((nodeOpts) => {
        nodeOpts["key"] = uuidv4();
        nodeOpts["required"] = true;
        this.helpers.nodeFormsKeys.push(nodeOpts["key"]);
      });
    },

    getOriUserInput() {
      const nodesOptions = this.$store.getters.reqData.nodesOptions;
      const nodeOptions = nodesOptions[0]; // 第一个和其它所有表单都行,这里取第一个节点配置表单

      // 配置节点配置表单的公共字段
      this.viewItems["cloud"] = nodeOptions.cloud;
      // this.viewItems["loginTypes"] = nodeOptions["sshKeyName"]
      //   ? "sshKeyName"
      //   : "loginPassword";
      // this.viewItems["sshKeyName"] = nodeOptions["sshKeyName"];
      // this.viewItems["loginPassword"] = nodeOptions["loginPassword"];
      // this.viewItems["loginUserName"] = nodeOptions["loginUserName"];

      // 配置节点配置表单的个性字段
      this.nodesOptions = nodesOptions;
    },

    getIndex(key) {
      return this.helpers.nodeFormsKeys.indexOf(key);
    },

    getValidation(key, validation) {
      const ind = this.getIndex(key);
      this.helpers.validationArr[ind] = validation;
    },

    addNodeForm() {
      if (this.nodesOptions.length === 5) {
        // 这儿可以给用户一个提示
        this.$message({
          message: "已经创建了5个节点, 不能再创建了",
          type: "success",
        });
        return false;
      }

      // 检查之前填写的表单是否全部通过验证
      const validation = this.helpers.validationArr.every(
        (validation) => validation
      );

      if (validation) {
        // 添加新节点配置表单
        const newNodeForm = { ...this.$options.nodeFormTemplate };
        newNodeForm["key"] = uuidv4();
        newNodeForm["required"] = false;
        this.helpers.nodeFormsKeys.push(newNodeForm["key"]);
        this.nodesOptions.push(newNodeForm);
        this.helpers.validationArr.push(false); // false
      } else {
        this.$message({
          message: "请检查填写的表单",
          type: "warning",
        });
      }
    },

    /**
     * 检查是否有修改尚未保存
     * 如果使用mouseLeave事件, 该方法可以废弃
     */
    checkChanges() {
      this.$refs.childForms.forEach((childForm) => {
        childForm.getUnsavedChanges();
      });

      const keys = Object.keys(this.$store.getters.unsavedChanges);

      if (keys.length === 0) {
        return false;
      }

      keys.sort((key1, key2) => {
        return this.getIndex(key1) - this.getIndex(key2);
      });

      let message = "";

      keys.forEach((key) => {
        const ind = this.getIndex(key);
        const oneFormChanges = this.$store.getters.unsavedChanges[key];
        message += "第" + (ind + 1) + "个节点表单有未保存的修改: ";

        for (let desc of oneFormChanges) {
          let { field, oldVal, newVal } = desc;
          message += "字段" + field + ": " + oldVal + " => " + newVal + "; ";
        }

        message += "         ";
      });

      this.$message({
        message: message,
        type: "warning",
      });

      return true;
    },

    onNextClick() {
      const hasChanged = this.checkChanges();
      if (hasChanged) {
        return; // 如果发生变化,则阻止用户进入下一步
      }

      // let defaultNum = 0; // 存储默认节点的数量
      // for (let nodesOption of this.nodesOptions) {
      //   if (nodesOption.type === "default") defaultNum += 1;
      // }

      // if (defaultNum < 1) {
      //   this.$message({
      //     showClose: true,
      //     message: "默认节点数小于1，无法完成创建，请重新配置",
      //     type: "error",
      //   });
      //   return;
      // }
      if (!this.viewItems.workerVswitchIDS.length) {
        this.$message({
          message: "请检查填写的表单项:请至少添加一个workerVswitchID",
          type: "warning",
        });
        return;
      }
      if (
        (this.viewItems.loginTypes === "loginKeyPair" &&
          this.viewItems.loginKeyPair === undefined) ||
        (this.viewItems.loginTypes === "loginPassword" &&
          this.viewItems.loginPassword === undefined)
      ) {
        this.$message({
          message: "请检查填写的表单项:节点公有配置表单授权信息未正确填写",
          type: "warning",
        });
        return;
      }
      if (
        this.viewItems.loginTypes === "loginPassword" &&
        (this.viewItems.loginPassword.length < 8 ||
          this.viewItems.loginPassword.length > 30)
      ) {
        this.$message({
          message: "请检查填写的表单项:节点公有配置表单授权密码长度为8-30位",
          type: "warning",
        });
        return;
      }
      // 判断表单是否均通过了验证
      const validation = this.helpers.validationArr.every((valid) => valid);
      const empty = (input) => {
        return (
          input + "" === "null" || input + "" === "undefined" || input === ""
        );
      };
      const viewItemsVali =
        Object.values(this.viewItems).every((item) => !empty(item)) &&
        (!empty(this.viewItems.loginPassword) ||
          !empty(this.viewItems.loginKeyPair));
      if (!validation || !viewItemsVali) {
        this.$message({
          message:
            "请检查填写的表单项" +
            (!viewItemsVali ? ":节点公有配置表单未填写完整" : ""),
          type: "warning",
        });

        return;
      }
      //如果用户选择loginPassword方式则加密
      if (!!this.viewItems.loginPassword)
        this.viewItems.loginPassword = this.$getRsaCode(
          this.viewItems.loginPassword
        );
      //其他公共配置放入driverOption
      this.$store.getters.reqData.driverOptions = Object.assign(
        this.viewItems,
        this.$store.getters.reqData.driverOptions
      );
      // 删除发送时不需要的两个字段 组装特殊的spotPriceLimit[{}]结构
      for (let nodeOpts of this.nodesOptions) {
        delete nodeOpts["key"];
        delete nodeOpts["required"];
        let spotPriceLimitArr = [];
        //把workerVswitchIDS给scalingGroupVswitchIds 在一开始填一次 后面直接付给每个node
        nodeOpts.scalingGroupVswitchIds =
          this.$store.getters.reqData.driverOptions.workerVswitchIDS;
        //把resourceGroupID给nodepoolInfoResourceGroupID 在一开始填一次 后面直接付给每个node
        console.log(
          "离开页面前",
          JSON.stringify(this.$store.getters.reqData.driverOptions)
        );
        nodeOpts.nodepoolInfoResourceGroupID =
          this.$store.getters.reqData.driverOptions.resourceGroupID;
        //driver里的密码付给各node
        this.viewItems.loginTypes === "loginPassword"
          ? (nodeOpts.loginPassword =
              this.$store.getters.reqData.driverOptions.loginPassword)
          : (nodeOpts.loginKeyPair =
              this.$store.getters.reqData.driverOptions.loginKeyPair);
        //autoScalingType数组转string 和scalingGroupSecurityGroupIds String转数组 并备份
        nodeOpts.autoScalingTypeCopy = JSON.parse(
          JSON.stringify(nodeOpts.autoScalingType)
        );
        nodeOpts.autoScalingType = nodeOpts.autoScalingType[0];
        nodeOpts.scalingGroupSecurityGroupIds = [
          nodeOpts.scalingGroupSecurityGroupIds === undefined
            ? ""
            : nodeOpts.scalingGroupSecurityGroupIds,
        ];

        // 跳下一步前进行备份 跳回时spotPriceLimit[{}]结构恢复为{} 以便修改输入
        nodeOpts.spotPriceLimitCopy = JSON.parse(
          JSON.stringify(nodeOpts.spotPriceLimit)
        );
        if (!!(JSON.stringify(nodeOpts.spotPriceLimit) === "{}")) {
          nodeOpts.spotPriceLimit = [{}];
        } else {
          for (let key in nodeOpts.spotPriceLimit) {
            let newPriceLimitObj = {};
            newPriceLimitObj["priceLimit"] = key;
            newPriceLimitObj["instanceType"] = nodeOpts.spotPriceLimit[key];
            spotPriceLimitArr.push(newPriceLimitObj);
          }
          nodeOpts.spotPriceLimit = spotPriceLimitArr;
        }
      }
      console.log(JSON.stringify(this.nodesOptions));
      this.$store.commit("setNodesOptions", this.nodesOptions);
      console.log("节点配置完成，即将进入应用配置...");
      this.$router.push("/form/appCommonConfig");
    },

    // isValidated(unValidatedFields) {
    //   if (unValidatedFields.hasOwnProperty("sshKeyName")) {
    //     //
    //     return (
    //       this.viewItems["loginPassword"] && this.viewItems["loginUserName"]
    //     );
    //   } else {
    //     return this.viewItems["sshKeyName"];
    //   }
    // },

    clearSaveLogInfo() {
      for (let [itemKey, itemVal] of Object.entries(this.viewItems)) {
        if (!Object.hasOwn(this.nodesOptions[0], itemKey)) {
          // 说明该键不在待提交的表单里, 可以不用管它 ; nodesOptions数组最少有2个节点
          continue;
        }

        // 改变模板的内容
        this.$options.nodeFormTemplate[itemKey] = itemVal;

        for (let nodeOpt of this.nodesOptions) {
          // 对于每个节点
          nodeOpt[itemKey] = itemVal;
        }
      }

      this.helpers.nodeFormsDisplay = true;
    },

    logInfoSave(formName) {
      this.$refs[formName].validate((valid, unValidatedFields) => {
        //cloud放入每一个节点配置里
        this.clearSaveLogInfo();
        // //其他公共配置放入driverOption
        // this.$store.getters.reqData.driverOptions = Object.assign(
        //   this.viewItems,
        //   this.$store.getters.reqData.driverOptions
        // );

        this.$message({
          message: "已为您添加默认节点，请根据需要追加节点配置",
          type: "success",
        });
        // } else {
        //   console.warn("验证失败");
        //   this.$message({
        //     message: "保存失败",
        //     type: "warning",
        //   });
        //   return false;
        // }
      });
    },

    deleteNodeForm(key) {
      const ind = this.getIndex(key);
      this.nodesOptions.splice(ind, 1);
      this.helpers.nodeFormsKeys.splice(ind, 1);
      this.helpers.validationArr.splice(ind, 1);
    },
  },
};
</script>

<style lang="less" scoped >
.apartFromNext {
  text-align: left;
  width: 600px;
}
.saveButton {
  margin-left: 50px;
  position: relative;
  width: 150px;
}
#zhanwei {
  height: 50px;
  width: 100%;
  margin: 0%;
}
.nodesForms {
  margin-bottom: 0.1rem;
}

.loginTypeChoose {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.newNodeButton {
  margin: 0 auto;
  width: 150px;
}
.title_text {
}
</style>